import React from 'react';
import Lottie from 'react-lottie';
import animationData from './data';

function FooterLogoAnimation() {
    const defaultOptions = {
        loop: true,
        autoplay: true,
        animationData: animationData,
        rendererSettings: {
          preserveAspectRatio: "xMidYMid slice"
        }
      };
    return (
    <div className="footer_logo">
        <Lottie 
          options={defaultOptions}
          height={108}
          width={114}
          className="footer_logo"
        />
      </div>
    )
}

export default FooterLogoAnimation;

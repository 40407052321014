import React from 'react';
import './WatchVideo.css';

function WatchVideo() {
    return (
        <div className="watch_video">
            <div className="watch_video_text">
                <p>Your Ride - Your Price</p>
                <div className="watch_video_btn">
                    <button className="cabtm_btn" style={{borderRadius: 8, color: '#040726'}} onClick={() => { window.open('https://www.youtube.com/channel/UCVAfdub96g-I9FkNu0WqNBA') }}>Watch Our Videos</button>
                    <button className="cabtm_btn" onClick={() => { window.location.replace('/faq') }} style={{borderRadius: 8, color: '#040726'}}>Read Our Faq</button>
                </div>
            </div>
        </div>
    )
}

export default WatchVideo;

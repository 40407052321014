import React, { useState, useEffect } from 'react';
import './TermsConditionScreen.css';
import axios from 'axios';

function MobileTermsConditionScreen(props) {
    const [data, setData] = useState([]);

    useEffect(() => {
        const fetchData = async () => {
            const { data } = await axios.get('/api/data/terms');
            setData(data);
        }

        fetchData();

        document.body.scrollTop = 0;
        document.documentElement.scrollTop = 0;
    }, []);

    const captainData = data.filter(item => (
       item.for === "cabtain"
    ));

    const customerData = data.filter(item => (
        item.for === "customer"
    ));

    console.log(captainData, customerData)
    return (
        <div id="terms_container">
            <div className="cabtm_body">
                <div className="cabtm_container faq_cabtm_container">
                    <div className="cabtm_privacy_content" style={{ display: 'flex', justifyContent: 'space-between', }}>
                        <div className="cabtm_privacy_content_content" style={{ width: "65%", marginTop: 20 }}>
                            { props.match.params.type === "cabtain" ? (
                                <>
                                    <p className="terms_content_info">Terms of Service: Rider (Captain)</p>
                                    {
                                        

                                        captainData.map((item, index) => (
                                            <div className="privacy_policy_container" style={{ width: '100%' }} key={index}>
                                                <p className="privacy_policy_heading">{index + 1}. {item.title}</p>
                                                
                                               
                                                <p className="privacy_policy_para" dangerouslySetInnerHTML={{__html:item.content}}></p>
                                    
                                            </div>
                                        ))
                                    }
                                </>
                            ) : props.match.params.type === "customer" ? (
                                <>
                                    <p className="terms_content_info">Terms of Service: Customer</p>
                                    {
                                        

                                        customerData.map((item, index) => (
                                            <div className="privacy_policy_container" style={{ width: '100%' }} key={index}>
                                                <p className="privacy_policy_heading">{index + 1}. {item.title}</p>
                                                
                                               
                                                <p className="privacy_policy_para" dangerouslySetInnerHTML={{__html:item.content}}></p>
                                    
                                            </div>
                                        ))
                                    }
                                </>
                            ) : (
                                null
                            )
                            }
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default MobileTermsConditionScreen;

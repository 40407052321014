import React, { useEffect } from 'react';
import About from '../components/About';
// import BlogSection from '../components/BlogSection';
import DownloadApp from '../components/DownloadApp';
import Thoughts from '../components/Thoughts';
import VideoHeader from '../components/VideoHeader';
import WatchVideo from '../components/WatchVideo';
import Footer from '../components/Footer';
import './HomeScreen.css';

function HomeScreen() {
    useEffect(() => {
        document.body.scrollTop = 0;
        document.documentElement.scrollTop = 0;

        // alert('Maintenance Message: CABTM is coming soon with Live on FEB 2022. Stay tune with us. If you would like to know more about CABTM, connect us at : support@cabtm.com')
    }, []);
    return (
        <div id="homescreen">
            <VideoHeader className="" />
            <section className="body">
                <About />
                <WatchVideo />
                {/* <div className="parallax" data-speed="-0.02"> */}
                    <DownloadApp />
                {/* </div> */}
                <Thoughts />
                {/* <BlogSection /> */}
                <Footer />
            </section>
        </div>
    )
}

export default HomeScreen;

import React, { useState, useEffect }  from 'react';
import Accordion from './Accordion';
import './FAQ.css';
import axios from 'axios';

function FAQ({ api, type }) {
    const [tab, setTab] = useState('customer');
    const [active, setActive] = useState(0);
    const [data, setData] = useState([]);
    // console.log(tab)
    const switchTab = (value, id, deactive) => {
        setTab(value);
        document.querySelector(`#${id}`).classList.add('active');
        document.querySelector(`#${deactive}`).classList.remove('active');
    }

    useEffect(() => {
        const fetchData = async () => {
            const { data } = await axios.get('/data/faq.json');
           
            setData(data.sort(function(a, b){return a.sort-b.sort}));
            // console.log(data);
        }

        if(type) {
            setTab(type)
        }

        fetchData();
    }, [type])

    return (
        <div className="faq" style={{ width: '100%' }}>
            <div className="faq_content">
                {
                    api !== 'mobile' ? (
                        <div className="faq_btn_container">
                            <button className="faq_btn active" onClick={() => { switchTab('customer', 'customer_btn', 'cabtain_btn') }} id="customer_btn">I'M CUSTOMER</button>

                            <button className="faq_btn" onClick={() => { switchTab('cabtain', 'cabtain_btn', 'customer_btn') }} id="cabtain_btn">I'M CABTAIN</button>
                        </div>
                    ) : (
                        null
                    )
                }
                
                {(() => {
                    if(tab === "customer"){
                        const customerFaq = data.filter(item => (
                            item.for === "customer"
                        )).sort(function(a, b){return a.sort-b.sort});

                        return(
                            
                            customerFaq.map((question, index) => (
                                <Accordion key={index} index={index} question={question.title} answer={question.content} active={active} setActive={setActive} />
                            ))
                        )
                    } else if(tab === "cabtain") {
                        const cabtainFaq = data.filter(item => (
                            item.for === "cabtain"
                        )).sort(function(a, b){return a.sort-b.sort});
                        
                        return(
                            
                            cabtainFaq.map((question, index) => (
                                <Accordion key={index} index={index} question={question.title} answer={question.content} active={active} setActive={setActive} />
                            ))
                        )
                    }
                })()}

            </div>
        </div>
    )
}

export default FAQ;

import React from 'react';

function ErrorScreen() {
    return (
        <div style={{ backgroundColor: 'var(--blue)', color: 'var(--white)', display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
            <h1>404 Bad Gateway</h1>
        </div>
    )
}

export default ErrorScreen;

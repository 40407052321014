import React, { useState } from 'react';
import './Slider.css';

function Slider({ slides }) {
    const [slideNo, setSlideNo] = useState(0);
    
    const slideRight = () => {
        document.querySelector(`.slider_card`).classList.add('slide-right');
        // document.querySelector('.slider_img').classList.add('slide-right');
        setTimeout(() => {
            document.querySelector(`.slider_card`).classList.remove('slide-right');
            // document.querySelector('.slider_img').classList.remove('slide-right');
        }, 1000);
    }
    return (
        <div className="slider">
            <div className="slider_card">
                <p className="slider_card_heading">{slides[slideNo].title}</p>
                <p className="slider_card_para">{slides[slideNo].para}</p>
            </div>

            <div className="slider_img">
                <img src={slides[slideNo].img} alt="" />
            </div>

            <div className="slider_btn_container">
                { slideNo > 0 ? (<button onClick={() => { setSlideNo(slideNo - 1); }}><img src="/assets/arrow-left-slider.svg" alt="" /></button>) : <button disabled><img src="/assets/arrow-left-slider.svg" alt="" /></button> }
                { slideNo < slides.length - 1 ? ( <button onClick={() => { setSlideNo(slideNo + 1); slideRight() }}><img src="/assets/arrow-right-slider.svg" alt="" /></button> ) : ( <button disabled><img src="/assets/arrow-right-slider.svg" alt="" /></button> ) }
                
            </div>
        </div>
    )
}

export default Slider

import React from 'react';
import './Header.css';

function Header({ img, small, main, transform, mainWidth, maxHeight, mobile, date, blog_header_title }) {
    return (
        <div className="header">
            <div className="header_content">
                <div>
                    <p className={`header_small`}><span className={blog_header_title}>{small}</span>{ date ? ( <span className="blog_header_date"> / {date}</span> ) : ( null ) }</p>
                    <p className={`header_main ${mobile}`} style={{ width: mainWidth }}>{main}</p>
                </div>
                <div>
                   { img ? ( <img src={img} alt="" className="header_img" style={{ transform: transform, height: maxHeight }} /> ) : ( <div className="header_img" style={{ transform: transform }}></div> ) } 
                </div>
            </div>
        </div>
    )
}

export default Header;

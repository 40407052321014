window.onload = () => {
    // console.log( );
    if(window.innerWidth < 600){
      return null;
    } else {
      window.addEventListener("scroll", function() {
        const distance = window.scrollY;
        // document.querySelector(".parallax").style.transform = `translateY(${-distance * 0.3}px)`;
        // console.log(document.querySelectorAll(".parallax"))
        for(let i = 0; i < document.querySelectorAll(".parallax").length;i++){
          if(document.querySelectorAll(".parallax")[i].attributes["data-speed"]){
            document.querySelectorAll(".parallax")[i].style.transform = `translateY(${distance * document.querySelectorAll(".parallax")[i].attributes["data-speed"].value}px)`;
          } else {
            document.querySelectorAll(".parallax")[i].style.transform = `translateY(${distance}px)`;
          }
          
          // console.log(document.querySelectorAll(".parallax")[i].attributes["data-speed"].value)
        }
        // document.querySelector(".container").style.transform = `translateY(${distance * 0.3}px)`;
      })
      // console.log(document.querySelectorAll(".parallax"));
      // for(let i = 0; i < document.querySelectorAll(".parallax").length;i++){
      //   console.log(i);
      // }
      // new Rellax('.rellax',{
      //   breakpoints:[576, 768, 1201]
      // });
    }

  }
import React from 'react';
import { NavLink } from 'react-router-dom';
import './VideoHeader.css';
import Lottie from 'react-lottie';
import animationData from './data';

function VideoHeader() {
    const defaultOptions = {
        loop: true,
        autoplay: true,
        animationData: animationData,
        rendererSettings: {
          preserveAspectRatio: "xMidYMid slice"
        }
      };
    return (
        <header>
            {/* <iframe title="video" frameBorder={0} src="https://www.youtube.com/embed/?controls=0&amp;rel=0&amp;autoplay=1&amp;loop=1&amp;mute=1&amp;theme=dark&amp;autohide=2&amp;fs=0&amp;showinfo=0&amp;wmode=opaque&amp;enablejsapi=1" allowFullScreen></iframe> */}
            <img src="/assets/hero.jpg" alt="" className="iframe" />
            {/* <video className="video" autoPlay muted loop>
                <source src="https://www.youtube.com/watch?ezuKIzXJuz8" />
            </video> */}
            {/* <iframe width="853" height="480" src="https://www.youtube.com/embed/ezuKIzXJuz8" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe> */}
            {/* <div className="parallax" data-speed="-0.07"> */}
            <div className="videoheader_container">
                <div className="videoheader_text">
                    <p className="h1">Nepal’s <span> 1st Fare Comparable & Trustable </span> Ride Sharing Product</p>
                    <p className="span">Your go to app for a hassle free life. We’re here to help you find affordable , fare comparable & reliable taxi services in town.</p>
                    <NavLink to="/earn"><button className="cabtm_btn" style={{borderRadius: 8, color: '#040726'}}><div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>Earn With Cabtm</div></button></NavLink>
                </div>
                <div className="videoheader_text">
                <Lottie 
                    options={defaultOptions}
                    height={208}
                    width={214}
                    className="videoheader_logo"
                />
                </div>
            </div>
            {/* </div> */}
        </header>
    )
}

export default VideoHeader;

import React from 'react';
import './DownloadApp.css';

function DownloadApp() {
    return (
        <div className="cabtm_container download_app" id="downloads">
            <div className="download_app_content">
                <div className="download_app_text">
                    <p className="download_app_text_heading">Download the app</p>
                    <span className="download_app_text_sub">Download our app & experience the services</span>

                    
                    <div className="footer_downloads">
                    {/* <div className="footer_downloads_item">
                    <p className="downloads_title">Coming Soon....</p>
                    </div> */}
                    <div className="footer_downloads_item">
                        <p className="downloads_title">CABTM USER APP</p>
                        <div className="downloads_links">
                            <a href="https://play.google.com/store/apps/details?id=com.cabtm"><img src="/assets/google_play.png" className="google_play" alt="" /></a>
                            {/* <a href="/"><img src="/assets/app_store.png" className="app_store" alt="" /></a> */}
                        </div>
                    </div>

                    <div className="footer_downloads_item">
                        <p className="downloads_title">CABTM CABTAIN APP</p>
                        <div className="downloads_links">
                            <a href="https://play.google.com/store/apps/details?id=com.cabtmcabtain" target="_blank" rel="noreferrer"><img src="/assets/google_play.png" className="google_play" alt="" /></a>
                            {/* <a href="/"><img src="/assets/app_store.png" className="app_store" alt="" /></a> */}
                        </div>
                        
                    </div>
                </div>
                </div>
                <div className="download_app_photo">
                    <img src="/assets/cabtm-phone.png" alt="" />
                </div>
            </div>
        </div>
    )
}

export default DownloadApp;

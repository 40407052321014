import React, { useEffect } from 'react';
import Header from '../components/Header';
import Footer from '../components/Footer';
import './ContactScreen.css';

function ContactScreen() {
    useEffect(() => {
        document.body.scrollTop = 0;
        document.documentElement.scrollTop = 0;
    }, []);
    return (
        <div className="contact_container">
            <Header img="/assets/contact_us_icon.svg" small="Contact Us" main="Let's Get Connected" mainWidth="100%" />
            {/* <div className="parallax" data-speed="-0.4"> */}
            <div className="cabtm_body">
                <div className="cabtm_container contact_cabtm_container">
                    <div className="cabtm_container_content">
                        <div className="contact_map_container">
                            <span onClick={() => { window.open('https://goo.gl/maps/nU5K6omEGtUnfU2e7') }}>VIEW IN MAP</span>
                        </div>
                        <div className="contact_content_container" style={{ width: '29%' }}>
                            <div className="contact_item">
                                <p className="contact_item_heading">Address:</p>
                                <p className="contact_item_para">Cabtm Nepal Pvt.Ltd. Ranibari Marg, Samakhusi Kathmandu, Nepal</p>
                            </div>
                            <hr style={{ backgroundColor: 'var(--lightgrey)', height: 2 }} />
                            <div className="contact_item">
                                <p className="contact_item_heading">Phone:</p>
                                <p className="contact_item_para"><a href="tel:+9779861736134">+977 9861736134</a> <br /> <a href="tel:+9779847557697">+977 9847557697</a> <br /> <a href="tel:014956900">01-4956900</a></p>
                            </div> 
                            <hr style={{ backgroundColor: 'var(--lightgrey)', height: 2 }} />
                            <div className="contact_item">
                                <p className="contact_item_heading">Email</p>
                                {/* <p className="contact_item_para"><a href="mailto:contact@cabtm.com">contact@cabtm.com</a> <br /> <a href="mailto:support@cabtm.com">support@cabtm.com</a></p> */}
                                <p className="contact_item_para"><a href="mailto:officials@cabtm.com">officials@cabtm.com</a></p>

                            </div>
                        </div>
                    </div>
                </div>
                <Footer />
            </div>
            {/* </div> */}
        </div>
    )
}

export default ContactScreen;

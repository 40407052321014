import React, { useEffect } from 'react';
import FAQ from '../components/FAQ';
import Header from '../components/Header';
import HeaderCard from '../components/HeaderCard';
import SliderSection from '../components/SliderSection';
import Footer from '../components/Footer';
import './EarnWithCabtianScreen.css';

function EarnWithCabtianScreen() {
    useEffect(() => {
        document.body.scrollTop = 0;
        document.documentElement.scrollTop = 0;
    }, []);
    return (
        <div id="earnwithcabtm">
            <Header img="/assets/due_amount_icon.svg" small="Earn With Cabtm" main="Become a cabtain & enjoy the flexibility of earning"  />
            {/* <div className="parallax" data-speed="-0.4"> */}
            <div className="cabtm_body">
                <div className="cabtm_container pattern" >
                    <div className="cabtm_container_content">
                        {
                            [
                                {
                                    icon: '/assets/car_side.svg',
                                    heading: 'Take a ride at your convenience',
                                    paragraph: "Struggling on finding customer(s)? Just kindly, turn on the location while using the app and wait for the customer's ride request and take a ride where you want to move."
                                },
                                {
                                    icon: '/assets/bargained.svg',
                                    heading: 'Even Cabtain can Adjust the price',
                                    paragraph: `Are you not happy with the requested price? Don’t worry, Cabtm cab brings you the ease of access to adjust your price. 
                                    PS: you will not be allowed to adjust the price if the requested price is already set high than the actual price.`
                                },
                                {
                                    icon: '/assets/secure.svg',
                                    heading: 'Your Safety is our Priority',
                                    paragraph: 'Enjoy your ride with Cabtm Cab. We take care of you. You are protected with live tracking, emergency alert & ride-sharing insurance.'
                                }
                            ].map((card, index) => (
                                <HeaderCard key={index} icon={card.icon} heading={card.heading} paragraph={card.paragraph} />
                            ))
                        }

                    </div>
                </div>
                <SliderSection 
                    slider_img_text={{ p: "How can I get a ride requests?", span: "Try getting ride requests with Cabtm" }} 
                    background_image='/assets/slider_background_rider.png'
                    cabtm_slider_card={{ img: '/assets/EARN_WITH_CABTM/slider_card_rider.png', heading: 'Earning and saving money is in your hand', para: `Your earning shift is in your hand, you are the own boss of your time. The more you manage to take a ride request the more you earn. Therefore, make sure to turn on the location and wait for the ride request at the peak time such as office time (probably 8 AM -10 AM) and obviously off-to-home time (probably 5 PM-7 PM). 
                    Note: Not only you will earn, but you will also save your money with our Security Deposit feature.` }} 
                    slides={[
                        {
                            silde_no: 1,
                            title: "1. Download the Cabtain App?",
                            para: "First downloading Cabtm cabtain APP via playstore (For Android) or via App store (For IOS)",
                            img: '/assets/EARN_WITH_CABTM/earnslide1.png'
                        },
                        {
                            silde_no: 2,
                            title: "2. Registering your account via Mobile number (For new user) or Login via mobile number ",
                            para: "(Note: Your OTP verification is required)",
                            img: '/assets/EARN_WITH_CABTM/earnslide2.png'
                        },
                        {
                            silde_no: 3,
                            title: "3. Choosing your Vehicle Type (Cars /Bikes etc)",
                            img: '/assets/EARN_WITH_CABTM/earnslide3.png'
                        },
                        {
                            silde_no: 4,
                            title: "4. You will be asked to verify your documents such as your license documents, citizenship documents, bluebook documents etc",
                            img: '/assets/EARN_WITH_CABTM/earnslide4.png'
                        },
                        {
                            silde_no: 5,
                            title: "5. Once everything is submitted, Your profile will be sent for verification.",
                            img: '/assets/EARN_WITH_CABTM/earnslide5.png'
                        },
                        {
                            silde_no: 6,
                            title: "6. One of our representative will call you for Account activation and further assistance & Training regarding how to use it including your dashboard",
                            img: '/assets/EARN_WITH_CABTM/earnslide6.png'
                        },
                        {
                            silde_no: 7,
                            title: "7. Once every process is DONE, the only  thing you need to do is to wait for passenger's request",
                            img: '/assets/EARN_WITH_CABTM/earnslide7.png'
                        },
                        {
                            silde_no: 8,
                            title: "8. You can simply minimize the app and do  your things. You  will  still be  allowed to  accept/deal with passenger's ride request.",
                            para: "Note: Make sure to turn on the location.",
                            img: '/assets/EARN_WITH_CABTM/earnslide8.png'
                        }
                    ]}
                />

                
                <hr style={{ backgroundColor: 'rgba(73, 74, 126, 0.5)', height: 3, transform: 'translateY(-90px)' }} />

                <div className="faq_container">

                    <p className="faq_container_title">Frequently Asked Questions</p>
                    <FAQ />
                    <Footer />
                </div>
            </div>
            {/* </div> */}
        </div>
    )
}

export default EarnWithCabtianScreen;

import React from 'react';
import './Thoughts.css';

function Thoughts() {
    return (
        <div className="thought_container">
            <div className="thought_content">
                <p className="thought_text">Empowering, simplifying and improving <br /> the lives of people via technology</p>

                <div className="thought_card_container">
                   
                    <div className="thought_card">
                        <a href="https://techsathi.com/bargaining-on-ride-sharing-with-cabtm" target="_blank" rel="noreferrer" >
                        <div className="thought_card_img">
                            <img src="/assets/thought_img_driver.png" alt="" />
                        </div>
                        <div className="thought_card_info">
                            <p className="thought_card_text">Bargaining on ride sharing with CABTM</p>
                        </div>
                        
                        </a>
                    </div>
                    
                    <div className="thought_card">
                        <a href="https://www.tiktok.com/tag/cabtm" target="_blank" rel="noreferrer">
                        <div className="thought_card_img">
                            <img src="/assets/articles/COOL_BOY_HOMEPAGE_IMAGE.jpg" alt="" />
                        </div>
                        <div className="thought_card_info">
                            <p className="thought_card_text">CABTM is also popular on TikTok - Watch Now.</p>
                        </div>
                        </a>
                    </div>

                    <div className="thought_card">
                        <a href="https://myrepublica.nagariknetwork.com/news/cabtm-nepal-launches-new-ride-sharing-app-with-various-features/" target="_blank" rel="noreferrer">
                        <div className="thought_card_img">
                            <img src="/assets/articles/MY_RUPBLICA.jpg" alt="" />
                        </div>
                        <div className="thought_card_info">
                            <p className="thought_card_text">What myRepublica says about new features on Cabtm?</p>
                        </div>
                        </a>
                    </div>

                    <div className="thought_card">
                        <a href="https://www.nepwave.com/tired-of-hanging-in-a-crowded-vehicle-heres-top-7-ride-hailing-service-providers-in-nepal/" target="_blank" rel="noreferrer">
                        <div className="thought_card_img">
                            <img src="/assets/articles/FOR_NEPWAVE.jpg" alt="" />
                        </div>
                        <div className="thought_card_info">
                            <p className="thought_card_text">We're listed on Top 7 Ride-Hailing service Providers in Nepal 2022</p>
                        </div>
                        </a>
                    </div>
                </div>
            </div>
            
            {/* <div className="media_content"> */}
                {/* <p className="thought_text">Press/Media Coverage</p> */}

                {/* <div className="media_card_container"> */}
                    {/* <div className="media_card"> */}
                        {/* <div className="media_card_img">
                            <img src="/assets/logo.png" alt="" />
                        </div> */}
                        {/* <div className="media_card_text">
                            <p className="media_card_heading">Bargaining on CABTM had made me feel more connected.</p>
                            <p className="media_card_para">"I was running out of time and suddenly, I had to rush through Mid Baneswor and I just had 300 in my pocket, I had to hop into the taxi because of heavy luggage so I quickly Logged-In to CABTM and found that the price shown was 346. It made me sad then my eyes quickly hit onto the "BARGAIN" and when I click the "Bargain", I was so happy to slide through the amount 300 which I had. I want to thank CABTM for touching our lives.", - Lekh Bahadur Neupane</p>

                        </div> */}
                    {/* </div> */}
                {/* </div> */}
            {/* </div> */}
        </div>
    )
}

export default Thoughts;

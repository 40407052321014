import React from 'react';
import './HeaderCard.css';

function HeaderCard({ icon, heading, paragraph }) {
    return (
        <div className="header_card">
            {/* <div className="header_card_container"> */}
            <div className="header_card_icon">
                {
                    icon === "/assets/bargained.svg" ? (
                        <img src={icon} alt="" className="bargained_icon" />
                    ) : icon === "/assets/money.svg" ? (
                        <img src={icon} alt="" className="money_icon" />
                    ) : icon === "/assets/secure.svg" ? (
                        <img src={icon} alt="" className="secure_icon" />
                    ) : (
                        <img src={icon} alt=""  />
                    )
                }
                
            </div>
            <div className="header_card_content">
                <p className="header_card_heading">{heading}</p>
                <p className="header_card_para">{paragraph}</p>
            </div>
            {/* </div> */}
        </div>
    )
}

export default HeaderCard;

import React, { useEffect } from 'react';
import FAQ from '../components/FAQ';
import Header from '../components/Header';
import Footer from '../components/Footer';
import './FAQScreen.css';

function FAQScreen() {
    useEffect(() => {
        document.body.scrollTop = 0;
        document.documentElement.scrollTop = 0;
    }, []);
    return (
        <div className="contact_container">
            <Header img="/assets/FAQ_icon.svg" main="Frequently Asked Questions" transform="translateY(-24px)" mobile="mobile_main" />
            {/* <div className="parallax" data-speed="-0.2"> */}
            <div className="cabtm_body">
                <div className="cabtm_container faq_cabtm_container">
                    <FAQ />
                </div>
                <Footer />
            </div>
            {/* </div> */}
        </div>
    )
}

export default FAQScreen;

import './App.css';
import './Animation.css';
import React, { useEffect } from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import HomeScreen from './screens/HomeScreen';
import Navbar from './components/Navbar';
import CabtianServicesScreenCab from './screens/CabtianServicesScreenCab';
import CabtianServicesScreenBike from './screens/CabtianServicesScreenBike';
import EarnWithCabtianScreen from './screens/EarnWithCabtianScreen';
import FAQScreen from './screens/FAQScreen';
import ContactScreen from './screens/ContactScreen';
import TermsConditionScreen from './screens/TermsConditionScreen';
import PrivacyPolicyScreen from './screens/PrivacyPolicyScreen';
// import BlogListScreen from './screens/BlogListScreen';
// import BlogScreen from './screens/BlogScreen';
// import LoginScreen from './screens/LoginScreen';
// import CustomSidebar from './components/CustomSidebar';
import './parallax';
import axios from 'axios';
// import TermsDashboardScreen from './screens/TermsDashboardScreen';
// import TermsEditorScreen from './screens/TermsEditorScreen';
// import FAQDashboardScreen from './screens/FAQDashboardScreen';
// import FAQEditorScreen from './screens/FAQEditorScreen';
// import BlogDashboardScreen from './screens/BlogDashboardScreen';
// import PrivacyDashboardScreen from './screens/PrivacyDashboardScreen';
// import PrivacyEditorScreen from './screens/PrivacyEditorScreen';
// import BlogEditorScreen from './screens/BlogEditorScreen';
import ErrorScreen from './screens/ErrorScreen';
import MobileFAQScreen from './screens/MobileFAQScreen';
import ToTop from './components/ToTop';
import MobileTermsConditionScreen from './screens/MobileTermsConditionScreen';

// axios.defaults.baseURL = 'https://webapi.cabtm.com';
// axios.defaults.baseURL = 'http://localhost:4000';
if(localStorage.getItem('user')){
  let user = JSON.parse(localStorage.getItem('user'));
  axios.defaults.headers.common['Authorization'] = user.auth_token;
}

function App() {
  useEffect(() => {
  //   const auth_check = async () => {
  //       const { data } = await axios.get('/authcheck');
  //       if (data.message !== 'Auth'){
  //         localStorage.removeItem('user');
  //         localStorage.removeItem('default');
  //       } else if (data.message === 'Invalid Token') {
  //         localStorage.removeItem('user');
  //         localStorage.removeItem('default');
  //         window.location.replace('/admin-login')
  //       }
  // }
  // auth_check();
  }, []);
  return (
    <Router>
    {/* { window.location.pathname.includes('/admin-login') ? ( 
        <div className="App">
        <Switch>
          <Route exact path="/" component={HomeScreen} />
          <Route path="/services" component={CabtianServicesScreen} />
          <Route path="/earn" component={EarnWithCabtianScreen} />
          <Route path="/faq" component={FAQScreen} />
          <Route path="/contact" component={ContactScreen} />
          <Route path="/terms" component={TermsConditionScreen} />
          <Route path="/privacy" component={PrivacyPolicyScreen} />
          <Route path="/blogs" component={BlogListScreen} />
          <Route path="/blog/:id" component={BlogScreen} />
          <Route path="/admin-login" component={LoginScreen} />
          <Route exact path="/dashboard/terms" component={TermsDashboardScreen} />
          <Route exact path="/dashboard/terms/cabtain" component={TermsEditorScreen} />
          <Route exact path="/dashboard/terms/customer" component={TermsEditorScreen} />
          <Route exact path="/dashboard/terms/cabtain/:id" component={TermsEditorScreen} />
          <Route exact path="/dashboard/terms/customer/:id" component={TermsEditorScreen} />
          <Route exact path="/dashboard/faq" component={FAQDashboardScreen} />
          <Route exact path="/dashboard/faq/cabtain" component={FAQEditorScreen} />
          <Route exact path="/dashboard/faq/customer" component={FAQEditorScreen} />
          <Route exact path="/dashboard/faq/cabtain/:id" component={FAQEditorScreen} />
          <Route exact path="/dashboard/faq/customer/:id" component={FAQEditorScreen} />
          <Route exact path="/dashboard/blog" component={BlogDashboardScreen} />
          <Route exact path="/dashboard/privacy" component={PrivacyDashboardScreen} />
          <Route exact path="/dashboard/privacy/create" component={PrivacyEditorScreen} />
          <Route exact path="/dashboard/privacy/:id" component={PrivacyEditorScreen} />
          <Route exact path="/dashboard/blog/create" component={BlogEditorScreen} />
          <Route exact path="/dashboard/blog/:id" component={BlogEditorScreen} />
          <Route path="/mobile/faq/:type" component={MobileFAQScreen} />
          <Route path="/mobile/terms/:type" component={MobileTermsConditionScreen} />
          <Route component={ErrorScreen} />
        </Switch>
        <ToTop />
      </div>
    ) : window.location.pathname.includes('/dashboard') ? ( 
      <div className="App" style={{ display: 'flex' }}>
      <CustomSidebar />
      <Switch>
        <Route exact path="/" component={HomeScreen} />
        <Route path="/services" component={CabtianServicesScreen} />
        <Route path="/earn" component={EarnWithCabtianScreen} />
        <Route path="/faq" component={FAQScreen} />
        <Route path="/contact" component={ContactScreen} />
        <Route path="/terms" component={TermsConditionScreen} />
        <Route path="/privacy" component={PrivacyPolicyScreen} />
        <Route path="/blogs" component={BlogListScreen} />
        <Route path="/blog/:id" component={BlogScreen} />
        <Route path="/admin-login" component={LoginScreen} />
        <Route exact path="/dashboard/terms" component={TermsDashboardScreen} />
        <Route exact path="/dashboard/terms/cabtain" component={TermsEditorScreen} />
        <Route exact path="/dashboard/terms/customer" component={TermsEditorScreen} />
        <Route exact path="/dashboard/terms/cabtain/:id" component={TermsEditorScreen} />
        <Route exact path="/dashboard/terms/customer/:id" component={TermsEditorScreen} />
        <Route exact path="/dashboard/faq" component={FAQDashboardScreen} />
        <Route exact path="/dashboard/faq/cabtain" component={FAQEditorScreen} />
        <Route exact path="/dashboard/faq/customer" component={FAQEditorScreen} />
        <Route exact path="/dashboard/faq/cabtain/:id" component={FAQEditorScreen} />
        <Route exact path="/dashboard/faq/customer/:id" component={FAQEditorScreen} />
        <Route exact path="/dashboard/blog" component={BlogDashboardScreen} />
        <Route exact path="/dashboard/privacy" component={PrivacyDashboardScreen} />
        <Route exact path="/dashboard/privacy/create" component={PrivacyEditorScreen} />
        <Route exact path="/dashboard/privacy/:id" component={PrivacyEditorScreen} />
        <Route exact path="/dashboard/blog/create" component={BlogEditorScreen} />
        <Route exact path="/dashboard/blog/:id" component={BlogEditorScreen} />
        <Route path="/mobile/faq/:type" component={MobileFAQScreen} />
        <Route path="/mobile/terms/:type" component={MobileTermsConditionScreen} />
        <Route component={ErrorScreen} />
      </Switch>
      <ToTop />
    </div>
    ) : (
      <div className="App">
      <Navbar />
      <Switch>
        <Route exact path="/" component={HomeScreen} />
        <Route path="/services" component={CabtianServicesScreen} />
        <Route path="/earn" component={EarnWithCabtianScreen} />
        <Route path="/faq" component={FAQScreen} />
        <Route path="/contact" component={ContactScreen} />
        <Route path="/terms" component={TermsConditionScreen} />
        <Route path="/privacy" component={PrivacyPolicyScreen} />
        <Route path="/blogs" component={BlogListScreen} />
        <Route path="/blog/:id" component={BlogScreen} />
        <Route path="/admin-login" component={LoginScreen} />
        <Route exact path="/dashboard/terms" component={TermsDashboardScreen} />
        <Route exact path="/dashboard/terms/cabtain" component={TermsEditorScreen} />
        <Route exact path="/dashboard/terms/customer" component={TermsEditorScreen} />
        <Route exact path="/dashboard/terms/cabtain/:id" component={TermsEditorScreen} />
        <Route exact path="/dashboard/terms/customer/:id" component={TermsEditorScreen} />
        <Route exact path="/dashboard/faq" component={FAQDashboardScreen} />
        <Route exact path="/dashboard/faq/cabtain" component={FAQEditorScreen} />
        <Route exact path="/dashboard/faq/customer" component={FAQEditorScreen} />
        <Route exact path="/dashboard/faq/cabtain/:id" component={FAQEditorScreen} />
        <Route exact path="/dashboard/faq/customer/:id" component={FAQEditorScreen} />
        <Route exact path="/dashboard/blog" component={BlogDashboardScreen} />
        <Route exact path="/dashboard/privacy" component={PrivacyDashboardScreen} />
        <Route exact path="/dashboard/privacy/create" component={PrivacyEditorScreen} />
        <Route exact path="/dashboard/privacy/:id" component={PrivacyEditorScreen} />
        <Route exact path="/dashboard/blog/create" component={BlogEditorScreen} />
        <Route exact path="/dashboard/blog/:id" component={BlogEditorScreen} />
        <Route path="/mobile/faq/:type" component={MobileFAQScreen} />
        <Route path="/mobile/terms/:type" component={MobileTermsConditionScreen} />
        <Route component={ErrorScreen} />
      </Switch>
      <ToTop />
    </div>
    ) }  */}
    <div className="App">
      <Navbar />
      <Switch>
        <Route exact path="/" component={HomeScreen} />
        <Route exact path="/services/cab" component={CabtianServicesScreenCab} />
        <Route exact path="/services/bike" component={CabtianServicesScreenBike} />
        <Route path="/earn" component={EarnWithCabtianScreen} />
        <Route path="/faq" component={FAQScreen} />
        <Route path="/contact" component={ContactScreen} />
        <Route path="/terms" component={TermsConditionScreen} />
        <Route path="/privacy" component={PrivacyPolicyScreen} />
        <Route path="/mobile/faq/:type" component={MobileFAQScreen} />
        <Route path="/mobile/terms/:type" component={MobileTermsConditionScreen} />
        <Route component={ErrorScreen} />
      </Switch>
      <ToTop />
    </div>
    </Router>
  );
}

export default App;
import React, { useEffect } from 'react';
import FAQ from '../components/FAQ';
import Header from '../components/Header';
import HeaderCard from '../components/HeaderCard';
import SliderSection from '../components/SliderSection';
import Footer from '../components/Footer';
import './CabtianServicesScreen.css';

function CabtianServicesScreenCab() {
    useEffect(() => {
        document.body.scrollTop = 0;
        document.documentElement.scrollTop = 0;
    }, []);
    return (
        <div id="cabtainservice">
            <Header img="/assets/header_logo.svg" small="Cabtm Cab" main="Enhancing tech-Friendly lives & ride with your price" mainWidth="70%" />
            {/* <div className="parallax" data-speed="-0.4"> */}
            <div className="cabtm_body">
                <div className="cabtm_container pattern">
                    <div className="cabtm_container_content">
                        {
                            [
                                {
                                    icon: '/assets/car.svg',
                                    heading: 'Book a ride at your convenience',
                                    paragraph: 'Struggling on finding taxi? Book a Cabtm Cab & get your taxi at your doorstep. Take a ride where you want to move.'
                                },
                                {
                                    icon: '/assets/money.svg',
                                    heading: 'Bargain with a price slider - Your choice of price',
                                    paragraph: 'Are you not happy with the recommended price? Don’t worry, Cabtm cab brings you ease of access to bargain in your price.'
                                },
                                {
                                    icon: '/assets/secure.svg',
                                    heading: 'Your Safety is our Priority',
                                    paragraph: 'Enjoy your ride with Cabtm Cab. We take care of you . You are protected with live tracking, emergency alert & ride sharing insurance. '
                                }
                            ].map((card, index)=> (
                                <HeaderCard key={index} icon={card.icon} heading={card.heading} paragraph={card.paragraph} />
                            ))
                        }

                    </div>
                </div>
                <SliderSection 
                    slider_img_text={{ p: "Let's ride with a pride", span: "Grab Cabtm Cab" }} 
                    background_image='/assets/slider_background.png'  
                    cabtm_slider_card={{ img: '/assets/cabtm-phone.png', heading: 'On-demand affordable rides for you', para: 'From a hectic schedule (9AM to  5PM) to a refreshing schedule 5PM to 9PM, Cabtm finds you the most nearest and cheapest cab / bike(s) to get to your destination. We’ll match you with a Cabtain (rider) in seconds, along with the quickest vehicle route.' }} 
                    slides={[
                        {
                            silde_no: 1,
                            title: "1. Select vehicle to choose your destination",
                            para: "Select vehicle type from explore screen to choose your destination.",
                            img: "/assets/CABTM_SERVICE/CABTM_CAB/slide1.png"
                        },
                        {
                            silde_no: 2,
                            title: "2. Set your destination location",
                            para: "Select destination location via set on map or by location search field",
                            img: "/assets/CABTM_SERVICE/CABTM_CAB/slide2.png"
                        },
                        {
                            silde_no: 3,
                            title: "3. Quickly Go with Recommended price or BARGAIN",
                            para: `If you are in a hurry and OK with the price, Request your Ride & Go for recommended Price
                            If you are not in a hurry and want to offer your price, Go for BARGAIN`,
                            img: "/assets/CABTM_SERVICE/CABTM_CAB/slide3.png"
                        },
                        {
                            silde_no: 4,
                            title: "4. Bargaining your price",
                            para: "If you choose to BARGAIN, A price slider is offered to bargain and confirm your fare",
                            img: "/assets/CABTM_SERVICE/CABTM_CAB/slide4.png"
                        },
                        {
                            silde_no: 5,
                            title: "5. Broadcasting your Fare",
                            para: "Either your offered fare (via BARGAIN) or the recommended fare will be broadcasted to all the CABTAINS near your pickup location.",
                            img: "/assets/CABTM_SERVICE/CABTM_CAB/slide5.png"
                        },
                        {
                            silde_no: 6,
                            title: "6. Handshaking and Confirming",
                            para: `CABTAINS are also allowed to Bargain (If not agreed) or Accept your Fare  (Agreed).

                            Note: CABTAINS are  not allowed to BARGAIN on  Requested Recommended Fare`,
                            img: "/assets/CABTM_SERVICE/CABTM_CAB/slide6.png"
                        },
                        {
                            silde_no: 7,
                            title: "7. Meeting at Pickup point",
                            para: "CABTAINS will notify via call or in-app chat to his/her passenger ",
                            img: "/assets/CABTM_SERVICE/CABTM_CAB/slide7.png"
                        },
                        {
                            silde_no: 8,
                            title: "8. Starting your Journey",
                            para: "Passenger(s) will have a seat and CABTAIN (rider) will Start the Ride",
                            img: "/assets/CABTM_SERVICE/CABTM_CAB/slide8.png"
                        },
                        {
                            silde_no: 9,
                            title: "9. Reaching the destination & Ending Ride",
                            para: "CABTAIN safely drops off his/her passenger(s) and End the Ride",
                            img: "/assets/CABTM_SERVICE/CABTM_CAB/slide9.png"
                        },
                        {
                            silde_no: 10,
                            title: "10. Digital/Cash payment & Happy Ending",
                            para: "CABTAIN's will be paid via Digital payment/Cash by his/her passenger",
                            img: "/assets/CABTM_SERVICE/CABTM_CAB/slide10.png"
                        },
                    ]}
                />

                
                <hr style={{ backgroundColor: 'rgba(73, 74, 126, 0.5)', height: 3, transform: 'translateY(-90px)' }} />
                <div className="faq_container">

                    <p className="faq_container_title">Frequently Asked Questions</p>
                    <FAQ />
                    <Footer />
                </div>
            {/* </div> */}
            </div>
        </div>
    )
}

export default CabtianServicesScreenCab;

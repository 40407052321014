import React from 'react';
import Slider from './Slider';
import './SliderSection.css';

function SliderSection({ slider_img_text, cabtm_slider_card, slides, background_image }) {
    return (
        <div className="slider_section">
            <div className="slider_img_container">
                <img src={background_image} className="slider_background" alt="" />
                
            </div>

            <div className="slider_img_text">
                    <p>{slider_img_text.p}</p>
                    <span>{slider_img_text.span}</span>
                </div>
            <div className="cabtm_container cabtm_slider_container" style={{  }}>
                <div className="cabtm_slider_content">
                    <Slider slides={slides} />

                    {
                        cabtm_slider_card ? (
                            <div className="cabtm_slider_card_container">
                                <div className="cabtm_slider_card_img">
                                    <img src={cabtm_slider_card?.img} alt="" />
                                </div>
                                <div className="cabtm_slider_card">
                                    <p className="cabtm_slider_card_heading">{cabtm_slider_card?.heading}</p>
                                    <p className="cabtm_slider_card_para">{cabtm_slider_card?.para}</p>
                                </div>
                            </div>
                        ) : (
                            null
                        )
                    }
                    
                    
                </div>
            </div>
        </div>
    )
}

export default SliderSection;

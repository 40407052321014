import React, { useState, useEffect } from 'react';
import Header from '../components/Header';
import Footer from '../components/Footer';
import './TermsConditionScreen.css';
import axios from 'axios';

function TermsConditionScreen() {
    const [tab, setTab] = useState('cabtain');
    const [data, setData] = useState([]);

    const switchTab = (value, id, deactive) => {
        setTab(value);
        document.querySelector(`#${id}`).classList.add('active');
        document.querySelector(`#${deactive}`).classList.remove('active');
        // console.log(tab)
    }

    // window.onload = () => {
    // var element = document.querySelector(".cabtm_privacy_content_link");
    // var sticky = element.offsetTop;

    // window.onscroll = () => {
    //     if (window.pageYOffset >= sticky) {
    //         element.classList.add("sticky")
    //     } else {
    //         element.classList.remove("sticky");
    //     }
    // };
    // }

    useEffect(() => {
        const fetchData = async () => {
            const { data } = await axios.get('/data/terms.json');
            setData(data.sort(function(a, b){return a.sort-b.sort}));
        }

        fetchData();

        document.body.scrollTop = 0;
        document.documentElement.scrollTop = 0;
    }, []);

    const captainData = data.filter(item => (
       item.for === "cabtain"
    )).sort(function(a, b){return a.sort-b.sort});

    const customerData = data.filter(item => (
        item.for === "customer"
    )).sort(function(a, b){return a.sort-b.sort});
    return (
        <div id="terms_container">
            <Header img="/assets/terms_&_conditions_icon.svg" small="Terms Of Services" main="Terms & Condition" mainWidth="100%" />
            <div className="cabtm_body">
                <div className="cabtm_container faq_cabtm_container">
                    <div className="cabtm_privacy_content" style={{ display: 'flex', justifyContent: 'space-between', }}>
                        <div className="cabtm_privacy_content_link" style={{ width: "30%" }}>
                            { tab === "cabtain" ? ( <div className="terms_link" id="cabtain-link" onClick={() => { switchTab('cabtain', 'cabtain-link', 'customer-link') }}><p className="active">Cabtain (Rider) </p> <img src="/assets/arrow-right-yellow.svg" alt="" /></div> ) :( <div className="terms_link" id="cabtain-link" onClick={() => { switchTab('cabtain', 'cabtain-link', 'customer-link') }}><p>Cabtain (Rider) </p> <img src="/assets/arrow-right-grey.svg" alt="" /></div> ) }
                            { tab === "customer" ? ( <div className="terms_link" id="customer-link" onClick={() => { switchTab('customer', 'customer-link', 'cabtain-link') }}><p className="active">Customer</p> <img src="/assets/arrow-right-yellow.svg" alt="" /></div> ) :( <div className="terms_link" id="customer-link" onClick={() => { switchTab('customer', 'customer-link', 'cabtain-link') }}><p>Customer</p> <img src="/assets/arrow-right-grey.svg" alt="" /></div> )} 
                        </div>
                        <div className="cabtm_privacy_content_content" style={{ width: "65%", marginTop: 20 }}>
                            { tab === "cabtain" ? (
                                <>
                                    <p className="terms_content_info">Terms of Service: Rider (Captain)</p>
                                    {
                                        

                                        captainData.map((item, index) => (
                                            <div className="privacy_policy_container" style={{ width: '100%' }}>
                                                <p className="privacy_policy_heading">{index + 1}. {item.title}</p>
                                                
                                               
                                                <p className="privacy_policy_para" dangerouslySetInnerHTML={{__html:item.content}}></p>
                                    
                                            </div>
                                        ))
                                    }
                                </>
                            ) : tab === "customer" ? (
                                <>
                                    <p className="terms_content_info">Terms of Service: Customer</p>
                                    {
                                        

                                        customerData.map((item, index) => (
                                            <div className="privacy_policy_container" style={{ width: '100%' }}>
                                                <p className="privacy_policy_heading">{index + 1}. {item.title}</p>
                                                
                                               
                                                <p className="privacy_policy_para" dangerouslySetInnerHTML={{__html:item.content}}></p>
                                    
                                            </div>
                                        ))
                                    }
                                </>
                            ) : (
                                null
                            )
                            }
                        </div>
                    </div>
                </div>
                <Footer />
            </div>
        </div>
    )
}

export default TermsConditionScreen;

import React from 'react';
import './Footer.css';
import { NavLink } from 'react-router-dom';
import FooterLogoAnimation from './FooterLogoAnimation';

function Footer() {
    // const location = useLocation();

    // useEffect(() => {
        // console.log(4571 - 1140);
        // console.log(location.pathname);
        // console.log(document.querySelector('.footer').style);
        // console.log(100 / 30)
        // document.querySelector('.footer').style.transform = `translateY(${4571 - 1140}px)`;
        // document.querySelector('.App').style.minHeight = "4571px";
        // window.innerHeight = 4571;
        // console.log(window.innerHeight);
        // switch (location.pathname) {
        // case "/":
        //     // document.querySelector('.footer').style.transform = "translateY(-31rem)";
        //     // document.querySelector('.App').style.maxHeight = "655vh";
        //     // document.querySelector('.footer').style.transform = `translateY(-${6765 - 1140}px)`;
        //     document.querySelector('.App').style.height = '6765px';
        //     break;
        // case "/services":

        //         // document.querySelector('.footer').style.transform = "translateY(-16rem)";
        //         // document.querySelector('.App').style.maxHeight = "438vh";
        //         break;
        // case "/earn":

        //         // document.querySelector('.footer').style.transform = "translateY(-16rem)";
        //         // document.querySelector('.App').style.maxHeight = "438vh";
        //         break;

        // case "/faq":
        //     // document.querySelector('.footer').style.transform = "translateY(-12rem)";
        //     // document.querySelector('.App').style.maxHeight = "180vh";
        //     break;

        // case "/contact":
        //     // document.querySelector('.footer').style.transform = "translateY(-10rem)";
        //     // document.querySelector('.App').style.maxHeight = "500vh";
        //     break;
            
        // default:
            
        //     break;
    //     }
    // }, [location.pathname]);
    // if(document.querySelector('.footer')){
    //     console.log("footer")
    // }
    return (
        <div className="footer">

        {/* <img src="/assets/footer_bg.svg" alt="" className="bg_img"  /> */}
        
        {/* <div className="footer_text"> */}
            <div className="footer_body">
                <div className="logo_slogn">
                {/* <img src="/assets/footer_logo.svg" alt="" className="footer_logo"  /> */}
                <FooterLogoAnimation />

                <img src="/assets/slogan.svg" alt="" className="slogan"  />
                </div>
            </div>

            <hr />

            <div className="footer_links_container">
                <div className="footer_links">
                    <div className="footer_link_item">
                        <p className="link_title">Product</p>
                        <div className="link_links">
                            <NavLink className="link_link" to="/services/cab">CABTM CAB</NavLink>
                            <NavLink className="link_link" to="/services/bike">CABTM BIKE</NavLink>
                        </div>
                        
                    </div>

                    <div className="footer_link_item">
                        <p className="link_title">Earn</p>
                        <div className="link_links">
                            <NavLink className="link_link" to="/earn">EARN WITH CABTM</NavLink>
                        </div>
                        
                    </div>

                    <div className="footer_link_item">
                        <p className="link_title">Company</p>
                        <div className="link_links">
                            <a className="link_link" href="/#about">ABOUT</a>
                            {/* <NavLink className="link_link" to="/blogs">BLOG</NavLink> */}
                            {/* <a className="link_link" href="/">SAFETY</a> */}
                            <NavLink className="link_link" to="/faq">FAQ</NavLink>
                        </div>
                        
                    </div>

                    <div className="footer_link_item">
                        <p className="link_title">Get in Touch</p>
                        <div className="link_links">
                            <NavLink className="link_link" to="/contact">CONTACT</NavLink>
                        </div>
                    </div>
                </div>
                
            </div>

            <hr />

            <div className="footer_socials_container">
                <div className="footer_socials">
                    <div className="footer_socials_item_icon">
                        <a href="https://www.facebook.com/cabtm" target="_blank" rel="noreferrer"><img src="/assets/facebook.svg" alt="" className="facebook_icon" /></a>

                        <a href="https://www.instagram.com/cabtmofficial/" target="_blank" rel="noreferrer"><img src="/assets/instagram.svg" alt="" className="youtube_icon" /></a>

                        <a href="https://twitter.com/cabtmofficial" target="_blank" rel="noreferrer"><img src="/assets/twitter.svg" alt="" className="youtube_icon" /></a>

                        <a href="https://www.linkedin.com/company/cabtm/" target="_blank" rel="noreferrer"><img src="/assets/linkedin.svg" alt="" className="youtube_icon" /></a>

                        <a href="https://www.youtube.com/channel/UCVAfdub96g-I9FkNu0WqNBA" target="_blank" rel="noreferrer"><img src="/assets/youtube.svg" alt="" className="youtube_icon" /></a>
                    </div>

                    <div className="footer_socials_item_link">
                        <NavLink className="social_link" to="/privacy" target="_blank">PRIVACY POLICY</NavLink>

                        <NavLink className="social_link" to="/terms" target="_blank">TERMS & CONDITIONS</NavLink>
                    </div>
                </div>
                
            </div>

            <hr />


            <div className="footer_downloads_container">
                <div className="footer_downloads">
                    <div className="footer_downloads_item">
                        <p className="downloads_title">CABTM USER APP</p>
                        <div className="downloads_links">
                            <a href="https://play.google.com/store/apps/details?id=com.cabtm" target="_blank" rel="noreferrer"><img src="/assets/google_play.png" className="google_play" alt="" /></a>
                            {/* <a href="/"><img src="/assets/app_store.png" className="app_store" alt="" /></a> */}
                        </div>
                    </div>

                    <div className="footer_downloads_item">
                        <p className="downloads_title">CABTM CAPTAIN APP</p>
                        <div className="downloads_links">
                            <a href="https://play.google.com/store/apps/details?id=com.cabtmcabtain" target="_blank" rel="noreferrer"><img src="/assets/google_play.png" className="google_play" alt="" /></a>
                            {/* <a href="/"><img src="/assets/app_store.png" className="app_store" alt="" /></a> */}
                        </div>
                        
                    </div>
                </div>
                <div className="copyright_container">
                    <p className="copyright">© 2022 CABTM NEPAL PVT. LTD. | All Rights Reserved.</p>
                    <p className="copyright">Design & Developed by <a href="http://yldesignstudio.com/" target="_blank" rel="noreferrer">Yeslab Design Studio Pvt.Ltd</a></p> 
                </div>
            </div>
            {/* </div> */}
        </div>
    )
}

export default Footer;

import React from 'react';
import './About.css';

function About() {
    return (
        <div className="cabtm_container about" id="about">
            <div className="about_text">
                <p className="about_heading">About Us</p>
                <p className="about_main">Cabtm is a vehicle mobility based product company providing the BARGAIN model On-demand ridesharing services via technology</p>
                <p className="about_sub">We’re only Nepal’s 1st fare bargaining ride-hailing Product company that operates in 3 major cities - Kathmandu, Bhaktapur & Lalitpur. We are established in January 2022 & working to build a tech-friendly mobility environment in the transport sector.</p>

                <div className="about_items_container">
                    <div className="about_item">
                        <p className="about_item_heading">Our Vision</p>
                        <p className="about_item_para">CABTM’s vision is to cut off the mediator and Groupism Pricing in Transport via Technology. CABTM is also working to create employment opportunities in digital Transport. <br /> <br />Now talking about the "Bargain", As we know that, "Bargain" has been common in most developing countries. Thus, making it easy to access via APP to go with your bargained price.</p>
                    </div>

                    <div className="about_item">
                        <p className="about_item_heading">Our Mission</p>
                        <p className="about_item_para">Our Mission is to bridge the communication effectively between the user and the CABTAIN(s) (Any rider related to transport), to uplift the CABTAIN’S economy rapidly, and getting CABTAIN(s) insured.</p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default About;

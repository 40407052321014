import React from 'react';
import './Accordion.css';

function Accordion({question, answer, index, active, setActive}) {
    return (
        <div className="faq_question_container ">
            {
                active !== index ? (
                    <div className="faq_question" onClick={() => { setActive(index); }}>
                        <div style={{ width: '5.5%' }}>
                            <img src="/assets/hand.svg" alt="" />
                        </div>
                                                
                        <div style={{ width: '90%' }}>
                            <p>{question}</p>
                        </div>
                
                        <div style={{ width: '4.7%' }} className="arrow_right_faq">
                            <img src="/assets/arrow-right.svg" alt="" style={{ width: 20, height: 20 }}  />
                        </div>
                    </div>
                ) : (
                    <div className="faq_question active" onClick={() => { setActive(index); }}>
                        <div style={{ width: '5.5%' }}>
                            <img src="/assets/active_hand.svg" alt="" />
                        </div>
                                                
                        <div style={{ width: '90%' }}>
                            <p>{question}</p>
                        </div>
                
                        <div style={{ width: '4.7%' }} className="arrow_right_faq">
                            <img src="/assets/active_arrow.svg" alt="" style={{ width: 20, height: 20 }} />
                        </div>
                    </div>
                )
            }
            
            {
                active === index  ? (
                    <div  className="faq_answer">
                        <p dangerouslySetInnerHTML={{__html:answer}}></p>
                    </div>
                ) : (
                    null
                )
            }
           
        </div>
    )
}

export default Accordion;

import React, { useState, useEffect } from 'react';
import Header from '../components/Header';
import Footer from '../components/Footer';
import './PrivacyPolicyScreen.css';
import axios from 'axios';

function PrivacyPolicyScreen() {
    const [data, setData] = useState([]);

    useEffect(() => { 
        const fetchData = async () => {
            const { data } = await axios.get('/data/privacy.json');

            setData(data.sort(function(a, b){return a.sort-b.sort}));
        }

        fetchData();

        document.body.scrollTop = 0;
        document.documentElement.scrollTop = 0;
    }, []);
    return (
        <div>
            <Header img="/assets/privacy_policy_icon.svg" small="Terms Of Policy" main="Privacy Policy" mainWidth="100%" />
            <div className="cabtm_body">
                <div className="cabtm_container faq_cabtm_container">
                    <div className="cabtm_privacy_content" style={{  }}>
                        {/* <div className="privacy_policy_info">
                            <p>This privacy policy describes how the Cabtm.com website and related application (the “Site”, “we” or “us”) collects, uses, shares and protects the personal information that we collect through this Site. Cabtm has established this Site to link up users who need something shipped or delivered (“Customers”) with individuals who will provide the shipping and delivery services (“Couriers”) or teams of affiliated Couriers (“Teams”). This policy also applies to any mobile applications that we develop for use with our services and Team specific pages on the Site, and references to this “Site”, “we” or “us” is intended to also include these mobile applications. Please read below to learn more about our information practices. By using this Site, you agree to these practices.</p>
                        </div> */}
                        
                        {
                            data.map(item => (
                                <div className="privacy_policy_container">
                                    <p className="privacy_policy_heading">{item.title}</p>
                                    
                                   
                                    <p className="privacy_policy_para" dangerouslySetInnerHTML={{__html:item.content}}></p>
                                    
                                    
                                </div>
                            ))
                        }
                    </div>
                </div>
                <Footer />
            </div>
        </div>
    )
}

export default PrivacyPolicyScreen;

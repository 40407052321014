import React, { useEffect } from 'react';
import FAQ from '../components/FAQ';
import './FAQScreen.css';

function MobileFAQScreen(props) {

    useEffect(() => {
        document.body.scrollTop = 0;
        document.documentElement.scrollTop = 0;
    }, [props.match.params.type]);
    return (
        <div className="contact_container">
            {/* <Header img="/assets/FAQ_icon.svg" main="Frequently Asked Questions" transform="translateY(18px)" mobile="mobile_main" /> */}
            {/* <div className="parallax" data-speed="-0.2"> */}
            {/* <div className="cabtm_body"> */}
                <div className="cabtm_container faq_cabtm_container">
                    <FAQ api="mobile" type={props.match.params.type} />
                </div>
                {/* <Footer /> */}
            {/* </div> */}
            {/* </div> */}
        </div>
    )
}

export default MobileFAQScreen;
